/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Human Resources Application
  Author: Hossam Ali
  Author URL: https://www.linkedin.com/in/hossam-ali-7bb41810b/
==========================================================================================*/

export default {
  items: [],
  HrRequests: [],
  penaltyReports: [],
  dailyReports: [],
  itemsVacation: [],
  itemsMission: [],
  itemsExcuse: [],
  itemsAbsence:[],
  itemsSammry:[],
  itemsWorkingLessthan6:[],
  itemsVariables: [],
  num_of_pages_variables: 0,
  items_export: []
}
