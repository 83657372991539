/*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===
  - Project: HR APPLICATION
  - Author: Salwa Abuwarda*
  - Author URL: http://linkedin.com/in/salwa-abuwarda-20b06a142
  ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*/

export default {
  items: [],
  total: 0,
  num_of_pages: 0
}
