import axios from "@/axios.js";


export default {
  // fetchItems({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`${process.env.VUE_APP_BASE_URI}/users/getAllHRWorkerPlanner`)
  //       .then(response => {
  //         commit("SET_ITEMS", response.data.data);
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  fetchItemsMainReport({ commit }, params) {

    return new Promise((resolve, reject) => {

      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/reports/main`, {
          params: {
            from: params.from,
            to: params.to,
            category_id: params.category_id,
            company_id: params.company_id,
            department_id: params.department_id,
            jobtitle_id: params.jobtitle_id,
            user_id: params.user_id,
            code: params.code,
            // cities: params.city_id,
            areas: params.area_id,
            status: params.status,
          }
        })
        .then(response => {
          commit("SET_ITEMS", response.data.response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchHrRequests({ commit }, params) {

    return new Promise((resolve, reject) => {

      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/hrRequests`, {
          params: {
            from_day: params.from,
            to_day: params.to,
            company_id: params.company_id,
            status: params.status,
            type: params.type,
          }
        })
        .then(response => {
          commit("SET_HRREQUESTS", response.data.response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchPenaltyReport({ commit }, params) {

    return new Promise((resolve, reject) => {

      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/reports/penaltyReport`, {
          params: {
            from_day: params.from,
            to_day: params.to,
            company_id: params.company_id,
          }
        })
        .then(response => {
          commit("SET_PAENALTY", response.data.response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchDailyReport({ commit }, params) {

    return new Promise((resolve, reject) => {

      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/reports/dailyReport`, {
          params: {
            from_day: params.from,
            to_day: params.to,
            company_id: params.company_id,
          }
        })
        .then(response => {
          commit("SET_DAILY", response.data.response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  exportItemsMainReport({ commit }, params) {

    return new Promise((resolve, reject) => {

      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/export/main-report`, {
          params: {
            from: params.from,
            to: params.to,
            category_id: params.category_id,
            company_id: params.company_id,
            department_id: params.department_id,
            jobtitle_id: params.jobtitle_id,
            user_id: params.user_id,
            code: params.code,
            // cities: params.city_id,
            areas: params.area_id,
            status: params.status,
          }
        })
        .then(response => {
          commit("SET_ITEMS_EXPORT", response.data.response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchItemsAllReport({ commit }, params) {

    return new Promise((resolve, reject) => {

      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/reports/signInOut`, {
          params: {
            from_day: params.from,
            to_day: params.to,
            company_id: params.company_id,
            jobtitle_id: params.jobtitle_id
          }
        })
        .then(response => {
          commit("SET_ITEMS", response.data.response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchItemsVacationReport({ commit }, params) {

    return new Promise((resolve, reject) => {

      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/reports/vacation`, {
          params: {
            from_day: params.from,
            to_day: params.to,
            category_id: params.category_id,
            company_id: params.company_id,
            department_id: params.department_id,
            jobtitle_id: params.jobtitle_id,
            user_id: params.user_id,
            code: params.code,
            // cities: params.city_id,
            areas: params.area_id,
            status: params.status,
            annual_pool: params.annual_pool
          }
        })
        .then(response => {
          commit("SET_ITEMSV", response.data.response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchItemsExcuseReport({ commit }, params) {

    return new Promise((resolve, reject) => {

      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/reports/execuses`, {
          params: {
            from: params.from,
            to: params.to,
            category_id: params.category_id,
            company_id: params.company_id,
            department_id: params.department_id,
            jobtitle_id: params.jobtitle_id,
            user_id: params.user_id,
            code: params.code,
            // cities: params.city_id,
            areas: params.area_id,
            status: params.status,
            nationalId: params.nationalId,
            manager_id: params.manager_id,
          }
        })
        .then(response => {

          commit("SET_ITEMSE", response.data.response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchItemsMissionReport({ commit }, params) {

    return new Promise((resolve, reject) => {

      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/reports/missions`, {
          params: {
            from: params.from,
            to: params.to,
            category_id: params.category_id,
            company_id: params.company_id,
            department_id: params.department_id,
            jobtitle_id: params.jobtitle_id,
            user_id: params.user_id,
            code: params.code,
            // cities: params.city_id,
            areas: params.area_id,
            status: params.status,
            nationalId: params.nationalId,
            manager_id: params.manager_id,
          }
        })
        .then(response => {

          commit("SET_ITEMSM", response.data.response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchItemsAbsenceReport({ commit }, params) {

    return new Promise((resolve, reject) => {

      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/reports/absence`, {
          params: {
            from_day: params.from_day,
            to_day: params.to_day,
            category_id: params.category_id,
            company_id: params.company_id,
            department_id: params.department_id,
            jobtitle_id: params.jobtitle_id,
            user_id: params.user_id,
            code: params.code,
            // cities: params.city_id,
            areas: params.area_id,
            status: params.status,
          }
        })
        .then(response => {

          commit("SET_ITEMSA", response.data.response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchItemsSammryReport({ commit }, params) {

    return new Promise((resolve, reject) => {

      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/reports/sammry`, {
          params: {
            from: params.from,
            to: params.to,
            category_id: params.category_id,
            company_id: params.company_id,
            department_id: params.department_id,
            jobtitle_id: params.jobtitle_id,
            user_id: params.user_id,
            code: params.code,
            // cities: params.city_id,
            areas: params.area_id,
            status: params.status,
          }
        })
        .then(response => {

          commit("SET_ITEMSS", response.data.response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchItemsWorkingLessthan6Report({ commit }, params) {

    return new Promise((resolve, reject) => {

      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/reports/sammry`, {
          params: {
            from: params.from,
            to: params.to,
            category_id: params.category_id,
            company_id: params.company_id,
            department_id: params.department_id,
            jobtitle_id: params.jobtitle_id,
            user_id: params.user_id,
            code: params.code,
            // cities: params.city_id,
            areas: params.area_id,
            status: params.status,
          }
        })
        .then(response => {

          commit("SET_ITEMSWL6", response.data.response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // fetchItemsVariablesReport({ commit }, items) {


  //   return new Promise((resolve, reject) => {

  //     axios
  //       .get(`${process.env.VUE_APP_BASE_URI}/dashboard/variable_report/`, {
  //         params: {
  //           start_date: items.start_date,
  //           end_date: items.end_date,
  //           category: items.category.name,
  //           company: items.company.name,
  //           department: items.department,
  //           job_title: items.job_title,
  //           employee_name: items.employee_name,
  //           employee_code: items.employee_code,
  //           reporting_to: items.reporting_to,
  //           city: items.city,
  //           area: items.area,
  //           area_name: items.area_name,
  //           page: items.num_of_pages
  //           // Absenteeism_Days: items.Absenteeism_Days
  //         }
  //       })
  //       .then(response => {
  //         // ////console.log('requested -> ' + items.start_date + ' - ' + items.end_date + ' - ' + items.category.name + ' - ' + items.company.name)
  //         // ////console.log('the items JSON is-> ' + JSON.stringify(response.data))
  //         commit("SET_ITEMS_NUM_PAGESVAR", response.data.number_of_pages);
  //         commit("SET_ITEMSVAR", response.data.data);
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },


  fetchItemsAttendance({ commit }, items) {

    items.start_date_ms = new Date(items.start_date_ms).getTime();
    items.end_date_ms = new Date(items.end_date_ms).getTime();

    // ////console.log( items)
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BASE_URI}/users/generate_hr_time_attendance`, {
          params: {
            start_date_ms: items.start_date_ms,
            end_date_ms: items.end_date_ms
          }
        })
        .then(response => {
          commit("SET_ITEMS", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchItemsTimeAttendance({ commit }, items) {

    items.start_date_ms = new Date(items.start_date_ms).getTime();
    items.end_date_ms = new Date(items.end_date_ms).getTime();
    // items = JSON.stringify(items);
    // ////console.log(items)
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BASE_URI}/users/generate_erp_hr_time_attendance`, {
          params: {
            start_date_ms: items.start_date_ms,
            end_date_ms: items.end_date_ms
          }
        })
        .then(response => {
          commit("SET_ITEMS", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
