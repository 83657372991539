export default {
  SET_ITEMS(state, items) {
    state.items = items;
  },
  SET_HRREQUESTS(state, items) {
    state.HrRequests = items;
  },
  SET_PAENALTY(state, items) {
    state.penaltyReports = items;
  },
  SET_DAILY(state, items) {
    state.dailyReports = items;
  },
  SET_ITEMSV(state, items) {
    state.itemsVacation = items;
  },
  SET_ITEMSM(state, items) {
    state.itemsMission = items;
  },
  SET_ITEMSE(state, items) {
    state.itemsExcuse = items;
  },
  SET_ITEMSA(state, items) {
    state.itemsAbsence = items;
  },
  SET_ITEMSS(state, items) {
    state.itemsSammry = items;
  },
  SET_ITEMSWL6(state, items) {
    state.itemsWorkingLessthan6 = items;
  },
  SET_ITEMSVAR(state, items) {
    state.itemsVariables = items;
  },
  SET_ITEMS_NUM_PAGESVAR(state, num) {
    state.num_of_pages_variables = num;
  },
  SET_ITEMS_EXPORT(state, items) {
    state.items_export = items;
  },
};
