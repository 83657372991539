/*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===
  - Project: HR APPLICATION
  - Author: Salwa Abuwarda*
  - Author URL: http://linkedin.com/in/salwa-abuwarda-20b06a142
  ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*/


import state from './moduleCategoriesManagementState.js'
import mutations from './moduleCategoriesManagementMutations.js'
import actions from './moduleCategoriesManagementActions.js'
import getters from './moduleCategoriesManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

