/*=========================================================================================
<!-- =========================================================================================
  Author: Nada Azzam
========================================================================================== -->

==========================================================================================*/


import state from './moduleDepartmentManagementState.js'
import mutations from './moduleDepartmentManagementMutations.js'
import actions from './moduleDepartmentManagementActions.js'
import getters from './moduleDepartmentManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

