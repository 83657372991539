/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Human Resources Application
  Author: Hazem Ashraf
  Author URL: https://www.linkedin.com/in/hazem-ashraf-1242169b/
==========================================================================================*/

export default {
  items: [],
  total: 0,
  num_of_pages: 0,
  itemsById:'',
  // meta: {
  //   total: 0,
  //   last_page: 0,
  //   current_page: 0,
  //   per_page: 0
  // }
}
