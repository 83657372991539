/*=========================================================================================
  Author: Hazem Ashraf
  Author URL: https://www.linkedin.com/in/hazem-ashraf-1242169b/
==========================================================================================*/

import axios from "@/axios.js";
import endpoints from "../../endpoints";

export default {
  getAlls({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoints.USER_ENDPOINT, {
          params: {
            company_id: params.company_id,
          }
        })
        .then(response => {
          commit("SET_ITEMS", response.data.response.data);
          // commit("SET_META", response.data.response.meta);
          resolve(response.data.response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${endpoints.USER_ENDPOINT}/${id}`)
        .then(res => {
          // console.log(res.data.response.data);
          commit("SET_ITEM_BY_ID", res.data.response.data);
          resolve(res.data.response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
