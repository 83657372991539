/*=========================================================================================
  Author: Hazem Ashraf
  Author URL: https://www.linkedin.com/in/hazem-ashraf-1242169b/
==========================================================================================*/

import axios from "@/axios.js";
import endpoints from "../../endpoints";

export default {
  getAlls ({ commit }) {
    
    return new Promise((resolve, reject) => {

      axios
        .get(endpoints.DEPARTMENT_ENDPOINT)
        .then(response => {
           commit("SET_ITEMS", response.data.response.data);
          resolve(response.data.response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getById({ commit }, id) {
    return new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.DEPARTMENT_ENDPOINT}/${id}`)
      .then((res) => {
        commit("SET_ITEM_BY_ID", res.data.response.data);
        resolve(res.data.response);
      }) .catch(error => {
        reject(error);
      });
    });

  },

};
